/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useRef, useState } from 'react'

import { Field, ErrorMessage } from 'formik'
import { KTSVG } from '../../../_metronic/helpers'

type Props = {
    setFieldValue?: any,
    values?: any
}

const Step2: FC<Props> = ({ setFieldValue, values }) => {

    const inputFile = useRef<HTMLInputElement | null>(null);
    const logoFile = useRef<HTMLInputElement | null>(null);



    const [logoImage, setLogoImage] = useState<string | null>('');

    const handleUpload = () => {
        inputFile.current?.click();
    }

    const handleChangeFile = (evt: any) => {
        console.log('file changed', evt.target.files)

        setFieldValue("logoFile", evt.target.files[0]);

        var reader = new FileReader();
        reader.readAsDataURL(evt.target.files[0]);
        reader.onload = function () {
            setLogoImage(reader.result!.toString());

        };

    }

    useEffect(() => {
        if((logoImage == '' || logoImage == null) && values?.companyLogo != logoImage) {
            setLogoImage(values?.companyLogo);
        }

        if(values && values.companyShowName && values.companyLargeLogo) {
            setFieldValue("companyLargeLogo", false);
        }
    }, [values]);

    return (
        <div className='w-100'>
            <div className='pb-10 pb-lg-15'>
                <h2 className='fw-bolder d-flex align-items-center text-dark'>
                    Dados da empresa
                </h2>
            </div>

            <div className='mb-10 fv-row'>
                <label className='form-label mb-3'>Nome da empresa</label>

                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='companyName'
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='companyName' />
                </div>
            </div>
            <div className='mb-10 fv-row'>
                <label className='form-label mb-3'>
                    Logo da empresa
                </label>


                <div className='py-4' style={{ border: '1px dashed #ccc', borderRadius: '5px', textAlign: 'center', position: "relative" }} onClick={handleUpload}>
                    {logoImage ? (<><i className='bi bi-pencil' style={{ fontSize: '16px', position: 'absolute', right: '10px', top: '10px' }}></i><img className='mw-250px mh-80px' src={logoImage} /></>) : (
                        <><i className='bi bi-cloud-arrow-up' style={{ fontSize: '24px' }}></i>  <h5 className='text-gray-800'>Fazer upload do logo</h5> <div>Clique para selecionar um arquivo</div></>
                    )}

                    <input type="file" ref={inputFile} onChange={handleChangeFile} style={{ display: 'none' }} accept="image/png, image/jpg, image/jpeg" />
                </div>
                {logoImage && (
                    <div className="form-text text-right"><a href='javascript:;' onClick={() => { setFieldValue("logoFile", null); setFieldValue("companyLogo", null); setLogoImage(null); inputFile.current!.value = ''; }}>remover logo</a></div>
                )}
            </div>
            <div className='mb-10 fv-row'>
                <label className=''>
                    <Field className='form-check-input me-3' type='checkbox' name="companyShowName" />
                    <span className='form-check-label fw-bold text-gray-700'>Exibir nome da empresa junto ao logo</span>
                </label>
            </div>
            <div className='mb-10 fv-row'>
                <label className=''>
                    <Field disabled={values && values.companyShowName} className='form-check-input me-3' type='checkbox' name="companyLargeLogo" />
                    <span className='form-check-label fw-bold text-gray-700'>Logo em tamanho grande</span>
                </label>
            </div>

        </div>
    )
}

export { Step2 }

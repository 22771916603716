/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, ForwardRefRenderFunction, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react'
import { QRScan } from '../../components/QRScan';

import { Field, ErrorMessage } from 'formik'
import { KTSVG } from '../../../_metronic/helpers'

const CodeInputElement: FC<{initialValue: string, onChange: (value:string) => void}> = ({initialValue, onChange}) => {
    const [isReadingQrCode, setIsReadingQrCode] = useState(false);
    const [code, setCode] = useState(initialValue);

    useEffect(() => {
        if(code != initialValue)
            setCode(initialValue);
    }, [initialValue]);




    useEffect(() => {
        
        onChange(code);
    }, [code])

    return (
        <div className='fv-row'>

                <label className='form-label mb-3'>Código do cartão ou tag</label>
<div className="input-group">
                <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='phone'
                    value={code}
                    onChange={(evt) => setCode(evt.target.value)}
                />
                <button className="btn btn-light-primary" type="button" onClick={(evt) => { setIsReadingQrCode(!isReadingQrCode) }}><i className="bi bi-camera-fill"></i></button>
                </div>
                {isReadingQrCode && (
        <QRScan onFind={(value:string) => { if(value.match(/https:\/\/i\.nfcon\.com\.br\/([A-Za-z0-9])+/)) { setCode(value.replace('https://i.nfcon.com.br/', '')); setIsReadingQrCode(false); } } } />
        )}
            </div>
    
);

}

type Props = {
    setFieldValue?: any, 
    initialCodes: string[]
}

const Step5Function: ForwardRefRenderFunction<any, Props> = ({setFieldValue, initialCodes}, ref) => {
const [codes, setCodes] = useState<string[]>(initialCodes);
const [enableAddCode, setEnableAddCode] = useState<boolean>(false);

useImperativeHandle(ref, () => ({
    reset: () => {
        setCodes(['']);
    },
    setCodes: (codes:[string]) => {
        setCodes(codes)
    }
}))

useEffect(() => {
    setEnableAddCode(codes[codes.length - 1] != '');

    if(setFieldValue)
        setFieldValue("codes", codes);
}, [codes])

    return (
        <div className='w-100' style={{minHeight: '70vh'}}>
            <div className='pb-10 pb-lg-15'>
                <h2 className='fw-bolder d-flex align-items-center text-dark'>
                    Associar cartões e tags
                </h2>
            </div>

{codes.map((item, index) => {
    return (
        <>
    <CodeInputElement key={index} initialValue={item} onChange={(value:string) => { var copy = [...codes]; copy[index] = value; setCodes(copy); }}  />
    <div className="form-text text-right mb-10">
        <a href='javascript:;' onClick={() => { var copy = [...codes]; copy.splice(index, 1); setCodes(copy); }}>remover cartão/tag</a>
        
        </div>
    </>
    )
})}
{enableAddCode && (
<button
                    onClick={() => { setCodes([...codes, '']) }}
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                    Adicionar outro cartão/tag
                  </button>)}
        </div>
    )
}
const Step5 = React.forwardRef(Step5Function);

export { Step5 }

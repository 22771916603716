// import { Formik } from "formik"
import * as Yup from 'yup'
import { FC, useRef, useState, useEffect } from "react"
import { StepperComponent } from "../../../_metronic/assets/ts/components"
import { KTSVG } from "../../../_metronic/helpers"
import { Step1 } from "./Step1"
import { Step2 } from "./Step2"
import { Step3 } from "./Step3"
import { Formik, Form, FormikValues, FormikProps, yupToFormErrors } from 'formik'
import { resolve } from 'path'
import axios from 'axios'
import { Step4 } from './Step4'
import { Step5 } from './Step5'
import { useParams } from 'react-router-dom'
import { LinkItem } from './types/LinkItem'
import { LinkType } from './types/LinkType'


const createCustomerSchemas = [
  Yup.object({
    accountType: Yup.string().required().label('Tipo de perfil'),
  }),
  Yup.object({
    companyName: Yup.string().when('accountType', { is: "corporate", then: Yup.string().required("Informe o nome da empresa") }),
    companyLogo: Yup.string().nullable(),
    companyUrl: Yup.string().nullable(),
    companyShowName: Yup.boolean(),
    logoFile: Yup.mixed().nullable(),
    pictureFile: Yup.mixed().nullable()
  }),
  Yup.object({
    firstName: Yup.string().when('accountType', { is: "personal", then: Yup.string().required('Informe o primeiro nome') }),
    lastName: Yup.string().when('accountType', { is: "personal", then: Yup.string().required('Informe o sobrenome') }),
    role: Yup.string(),
    bio: Yup.string(),
    username: Yup.string().required('Escolha um nome de usuário').matches(/^[a-zA-Z0-9\-\.]+$/, 'O nome de usuário deve conter apenas letras, números, ponto e hífen').test('checkDuplUsername', 'nome de usuário já existe', function (value) {
      return new Promise(async (resolve, reject) => {

        const { data } = await axios.get(`cards/check-username/${value}?id=${this.parent.id}`)

        resolve(data == 0);
      })
    }),
    cardColor: Yup.string()
  }),
  Yup.object({
    email: Yup.string(),
    phone: Yup.string(),
    instagram: Yup.string(),
    linkedin: Yup.string(),
    twitter: Yup.string(),
    youtube: Yup.string(),
    whatsapp: Yup.string(),
    tiktok: Yup.string(),
    facebook: Yup.string(),
    clubhouse: Yup.string(),
    snapchat: Yup.string(),
    discord: Yup.string(),
    kwai: Yup.string(),
    twitch: Yup.string(),
  }),
  Yup.object({
    codes: Yup.array().test('checkDuplCodes', 'Código já está em uso', function (value) {
      return new Promise(async (resolve, reject) => {

        var invalidCodes = [];
        for (var code of value as string[]) {
          if (code == '')
            continue;

          const { data } = await axios.get(`cards/check-shortcode/${code}?id=${this.parent.id}`)

          if (data > 0)
            invalidCodes.push(code)
        }

        var error = new Yup.ValidationError(`${invalidCodes.length > 1 ? 'Os códigos' : 'O código'} ${invalidCodes.join(", ")} já ${invalidCodes.length > 1 ? 'estão' : 'está'} em uso`);

        resolve(invalidCodes.length == 0 ? true : error);

      })
    })
  })
]


interface ICreateCustomer {
  id: string | null,
  accountType: string,
  companyName: string,
  companyLogo: string | null,
  companyUrl: string | null,
  companyShowName: boolean,
  picture: string | null,
  address: string,
  logoFile: any,
  pictureFile: any,
  firstName: string,
  lastName: string,
  role: string,
  bio: string,
  username: string,
  codes: string[],
  companyLargeLogo: boolean,
  links: LinkItem[],
  cardColor: string
}

const inits: ICreateCustomer = {
  id: null,
  accountType: 'corporate',
  companyName: '',
  companyLogo: null,
  companyUrl: null,
  companyShowName: false,
  picture: null,
  address: '',
  logoFile: null,
  pictureFile: null,
  firstName: '',
  lastName: '',
  role: '',
  bio: '',
  username: '',
  codes: [''],
  companyLargeLogo: false,
  links: [],
  cardColor: '#D8BEAD'
}

const NewCustomerWizard: FC = () => {

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const formikRef = useRef<FormikProps<ICreateCustomer> | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createCustomerSchemas[0])
  const [initValues, setInitValues] = useState<ICreateCustomer>(inits);
  const [isSaving, setIsSaving] = useState(false);
  const [types, setTypes] = useState<LinkType[]>([]);

  const { id } = useParams();

  type step5RefType = React.ElementRef<typeof Step5>;

  const step5Ref = useRef<step5RefType>();

  useEffect(() => {
    console.log(currentSchema);
  }, [currentSchema])

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev();
    // formikRef.current?.setFieldValue("links", onChangeStepRef.current());

    setCurrentSchema(createCustomerSchemas[stepper.current.currentStepIndex - 1])
  }

  const submitStep = async (values: ICreateCustomer, actions: FormikValues) => {
    console.log('called submit', values);
    if (!stepper.current) {
      return
    }

    // var links = onChangeStepRef.current();
    // console.log('getting links', links);
    // formikRef.current?.setFieldValue("links", links);

    setCurrentSchema(createCustomerSchemas[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {

      if (stepper.current.currentStepIndex == stepper.current.totatStepsNumber - 1) {

        setIsSaving(true);

        var formValues = formikRef.current?.values

        try {
          for (var schema of createCustomerSchemas) {
            await schema.validate(formValues, { abortEarly: false });
          }

          if (formValues?.logoFile) {
            var formData = new FormData();
            formData.append("image", values.logoFile);
            const resultLogo = await axios.post('upload/logo', formData, {
              headers: { "Content-Type": 'multipart/form-data' }
            });

            formValues.companyLogo = resultLogo.data.url;
          }

          if (formValues?.pictureFile) {
            var formData = new FormData();
            formData.append("image", values.pictureFile);
            const resultLogo = await axios.post('upload/logo', formData, {
              headers: { "Content-Type": 'multipart/form-data' }
            });

            formValues.picture = resultLogo.data.url;
          }

          if (!id) {

            const { data } = await axios.post('cards', formValues);

            setIsSaving(false);
            stepper.current.goNext();
          } else {
            const { data } = await axios.put(`cards/${id}`, formValues);
            setIsSaving(false);
            stepper.current.goNext();
          }

        } catch (err: any) {
          if (err.errors)
            alert(err.errors.join("\n"));
          else
            alert('Ocorreu um erro ao salvar os dados');
          setIsSaving(false);
        }
      } else {
        stepper.current.goNext()
      }
    } else {
      if (!id) {
        actions.resetForm();
        step5Ref.current.reset();
      }

      stepper.current.goto(1);

    }
  }

  const getTypes = async () => {
    const { data } = await axios.get('/link-types');
    setTypes(data);
    return data;
}

  const getData = async () => {
    var types:LinkType[] = await getTypes();

    if (id) {
      const { data } = await axios.get(`cards/${id}`);

      var codes = data.codes.map((x: any) => {
        return x.shortCode
      });

      var links: LinkItem[] = data.otherLinks ? data.otherLinks.map((x: any) => {
        return {
          type: types.find(type => type._id == x.type),
          text: x.text,
          value: x.value,
          orderProfile: x.orderProfile,
          showPdf: x.showPdf
        }
      }):[];

      console.log('links', links);


      var wizardData = {
        ...initValues,
        id: data._id,
        accountType: (data.company) ? 'corporate' : 'personal',
        companyName: data.company ? data.company.name : '',
        companyUrl: data.company ? data.company.url : null,
        companyShowName: data.company ? data.company.showName : false,
        picture: data.company ? null : data.picture,
        companyLargeLogo: data.company ? data.company.largeLogo : false,
        companyLogo: data.company ? data.company.logo : null,
        address: data.company ? data.company.address : null,
        firstName: data.firstName,
        lastName: data.lastName,
        role: data.role,
        bio: data.bio,
        username: data.userCode,
        phone: data.phone,
        whatsapp: data.whatsapp,
        email: data.email,
        links: links,
        codes: codes,
        cardColor: data.cardColor ?? '#D8BEAD'
      }

      step5Ref.current.setCodes(codes);

      formikRef.current?.setValues(wizardData);
    } else {
      formikRef.current?.setValues(initValues);
    }
  }

  useEffect(() => {
    getData();
    // formikRef.current?.setValues({ ...formikRef.current!.values, firstName: 'Victor' });
  }, [id]);

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef]);

  return (
    <div
      ref={stepperRef}
      className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
      id='kt_create_account_stepper'
    >
      {/* begin::Aside*/}
      <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
        {/* begin::Wrapper*/}
        <div className='card-body px-6 px-lg-10 px-xxl-15 py-20'>
          {/* begin::Nav*/}
          <div className='stepper-nav'>
            {/* begin::Step 1*/}
            <div className='stepper-item current' data-kt-stepper-element='nav' onClick={() => { stepper.current?.goto(1); setCurrentSchema(createCustomerSchemas[0]) }}>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>1</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Tipo de perfil</h3>

                  <div className='stepper-desc fw-semibold'>Empresarial ou pessoal</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 1*/}

            {/* begin::Step 2*/}
            <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => { stepper.current?.goto(2); setCurrentSchema(createCustomerSchemas[1]) }}>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>2</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Empresa</h3>
                  <div className='stepper-desc fw-semibold'>Dados da empresa</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 2*/}

            {/* begin::Step 3*/}
            <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => { stepper.current?.goto(3); setCurrentSchema(createCustomerSchemas[2]) }}>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>3</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Dados do perfil</h3>
                  <div className='stepper-desc fw-semibold'>Dados do profissional</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 3*/}

            {/* begin::Step 4*/}
            <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => { stepper.current?.goto(4); setCurrentSchema(createCustomerSchemas[3]) }}>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>4</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Contato e Links</h3>
                  <div className='stepper-desc fw-semibold'>Dados de contato, redes sociais</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 4*/}

            {/* begin::Step 5*/}
            <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => { stepper.current?.goto(5); setCurrentSchema(createCustomerSchemas[4]) }}>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>5</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Tags e cartões</h3>
                  <div className='stepper-desc fw-semibold'>Associar tags ou cartões NFC</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}
              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 5*/}

            {/* begin::Step 6*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>6</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Concluído</h3>
                  <div className='stepper-desc fw-semibold'>Tudo pronto!</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}
            </div>
            {/* end::Step 6*/}
          </div>
          {/* end::Nav*/}
        </div>
        {/* end::Wrapper*/}
      </div>
      {/* begin::Aside*/}

      <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
        <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep} innerRef={formikRef}>
          {() => (
            <Form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>
              <div className='current' data-kt-stepper-element='content'>
                <Step1 />
              </div>

              <div data-kt-stepper-element='content'>
                {formikRef.current?.values.accountType == 'personal' ? (<>Conta pessoal</>) : (<Step2 setFieldValue={formikRef.current?.setFieldValue} values={formikRef.current?.values} />)}
              </div>

              <div data-kt-stepper-element='content'>
                <Step3 setFieldValue={formikRef.current?.setFieldValue} values={formikRef.current?.values} />
              </div>

              <div data-kt-stepper-element='content'>
                <Step4 setFieldValue={formikRef.current?.setFieldValue} values={formikRef.current?.values} />
              </div>

              <div data-kt-stepper-element='content'>
                <Step5 ref={step5Ref} setFieldValue={formikRef.current?.setFieldValue} initialCodes={formikRef.current?.values.codes ?? ['']} />
              </div>

              <div data-kt-stepper-element='content'>
                <div className='w-100'>
                  <div className='pb-10 pb-lg-15'>
                    <h2 className='fw-bolder d-flex align-items-center text-dark'>
                      Perfil {!id ? 'criado' : 'atualizado'} com sucesso
                    </h2>
                  </div>
                  <div>Tudo pronto!</div>
                </div>
                {/* <Step5 /> */}
              </div>

              <div className='d-flex flex-stack pt-10'>

                <div className='mr-2'>
                  {stepper.current && stepper.current!.currentStepIndex < stepper.current!.totatStepsNumber && (
                    <button
                      onClick={prevStep}
                      type='button'
                      className='btn btn-lg btn-light-primary me-3'
                      data-kt-stepper-action='previous'
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr063.svg'
                        className='svg-icon-4 me-1'
                      />
                      Voltar
                    </button>
                  )}
                </div>


                <div>
                  <button type='submit' className='btn btn-lg btn-primary me-3'>
                    <span className='indicator-label'>
                      {!stepper.current || stepper.current!.currentStepIndex <
                        stepper.current?.totatStepsNumber! - 1 && 'Continuar'}
                      {stepper.current?.currentStepIndex ===
                        stepper.current?.totatStepsNumber! - 1 && !isSaving && 'Salvar'}
                      {stepper.current?.currentStepIndex ===
                        stepper.current?.totatStepsNumber! - 1 && isSaving && 'Salvando... '}
                      {stepper.current && stepper.current?.currentStepIndex ===
                        stepper.current?.totatStepsNumber! && !id && 'Novo cliente'}
                      {stepper.current && stepper.current?.currentStepIndex ===
                        stepper.current?.totatStepsNumber! && id != null && 'Voltar'}

                      {isSaving ? (<span className='spinner-border spinner-border-sm align-middle ms-2'></span>) : (
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                      )}
                    </span>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export { NewCustomerWizard }

import { useAuth0 } from "@auth0/auth0-react";
import { PageTitle } from "../../../_metronic/layout/core";
import { NewCustomerWizard } from "../customer/newCustomerWizard";

const NewCustomerPage = () => {
    const { user } = useAuth0();

    return (
        <>
            <PageTitle description='' breadcrumbs={[]}>
                {`Novo cliente`}
            </PageTitle>

            <NewCustomerWizard />

        </>
    )
}

export { NewCustomerPage }
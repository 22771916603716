/* eslint-disable jsx-a11y/anchor-is-valid */
import jsQR from 'jsqr';
import React, {FC, useEffect, useRef, useState} from 'react'

interface Props {
  onFind: any
}

const QRScan: FC<Props> = ({ onFind }) => {

  const [notEnabled, setNotEnabled] = useState(true);
  const [loading, setLoading] = useState(true);

  const streamRef = useRef<MediaStream>();
  const videoRef = useRef<HTMLVideoElement>();

  useEffect(() => {
    console.log('initializing qrcode scanner');
    const videoElm = document.createElement('video');
    const canvasElement = document.getElementById('qrCanvas') as HTMLCanvasElement;
    const canvas = canvasElement?.getContext('2d');

    videoRef.current = videoElm;

    const tick = () => {
      if(!videoRef.current) 
        return;

      if(notEnabled) {
        setNotEnabled(false);
      }
      if (videoRef.current?.readyState === videoRef.current?.HAVE_ENOUGH_DATA) {
        if (loading) setLoading(false);
        
        canvasElement.width = canvasElement.parentElement!.clientWidth;
        canvasElement.height = videoRef.current!.videoHeight * (canvasElement.width / videoRef.current!.videoWidth);
        
        canvas?.drawImage(
          videoRef.current!,
          0,
          0,
          canvasElement.width,
          canvasElement.height
        );
        var imageData = canvas?.getImageData(
          0,
          0,
          canvasElement.width,
          canvasElement.height
        );
        var code = jsQR(imageData!.data, imageData!.width, imageData!.height, {
          inversionAttempts: 'dontInvert',
        });
        if (code) {
          onFind(code.data);
        }
      }
      
      requestAnimationFrame(tick);
    };

    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: 'environment' } })
      .then(function(stream) {

        console.log('then stream')
        streamRef.current = stream;

        videoRef.current!.srcObject = stream;
        videoRef.current!.setAttribute('playsinline', 'true');
        videoRef.current!.play();
        requestAnimationFrame(tick);
      });

      return () => {
        console.log('destructing qrcode scanner');
        
        streamRef.current?.getTracks().forEach(function(track:any) {
          track.stop();
        });

        videoRef.current = undefined;
      }

    
  }, []);

  return (
    <div>
        {notEnabled ? (
          <div className='text-center mt-3'>
          <span role='img' aria-label='camera'>
            🎥
          </span>{' '}
          Câmera não está pronta
        </div>
        ) : (
          <div className='text-center mt-3'>
            {loading && (
              <>
              <span role='img' aria-label='time'>
            ⌛
          </span>{' '}
          Carregando...
              </>
            )}
            </div>
        )}
        <canvas id='qrCanvas' style={{ maxWidth: '100%', marginTop: '10px', borderRadius: '10px' }} />
      </div>
  )
}

export { QRScan }
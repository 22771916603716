import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useEffect, useState } from 'react'



const instance = axios.create({
    baseURL:  process.env.REACT_APP_API_URL
})

const AxiosInterceptor = ({ children }: {children: any}) : any => {

    const { getAccessTokenSilently, user, isLoading } = useAuth0();
    const [justLoaded, setJustLoaded] = useState(true);
    
    useEffect(() => {

        console.log('changed', isLoading,  user);

    var interceptor = axios.interceptors.request.use(
        async (config: any) => {

const token = await getAccessTokenSilently();
  
      config.headers.Authorization = `Bearer ${token}`;
      config.baseURL = process.env.REACT_APP_API_URL;


return config;
        });

        
        // workaround: wait 200ms before rendering content
        if(!isLoading && justLoaded) {
            setTimeout(() => { setJustLoaded(false)}, 200);
            }
        

return () => instance.interceptors.response.eject(interceptor);

    }, [isLoading]);

    
    return (!isLoading && !justLoaded) ? children : "loading";
}


export default instance;
export { AxiosInterceptor }
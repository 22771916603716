import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { isInteger } from 'formik';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle} from '../../../_metronic/layout/core'

const QrCodeGeneratorPage = () => {
const [qty, setQty] = useState('');
const [isLoading, setIsLoading] = useState(false);
const [preset, setPreset] = useState('white');

const handleDownload = async () => {
    if(!isInteger(qty)) {
        alert('Número inválido')
return;
    }
    const {data} = await axios.get(`id/${qty}?preset=${preset}`, { responseType: 'blob' });
    const href = URL.createObjectURL(data);

    // create "a" HTLM element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'qrcodes.zip'); //or any other extension
    // document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    // document.body.removeChild(link);
    // URL.revokeObjectURL(href);
}

  return (
  <>

<div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Gerar QR Codes</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Gere QR Codes para pré-impressão de tags e cartões</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='mb-10 fv-row w-100 w-xl-50'>
                <label className='form-label mb-3'>Quantidade de QR Codes</label>
                <input
                    type='text'
                    value={qty}
                    onChange={(e) => { setQty(e.target.value)} }
                    className='form-control form-control-lg form-control-solid'
                    name='companyName'
                />
            </div>
            <div className='mb-10 fv-row w-100 w-xl-50'>
                <label className='form-label mb-3'>Padrão de QR Code</label>

                <select
                    className='form-control form-control-lg form-control-solid'
                    value={preset}
                    onChange={(e) => { setPreset(e.target.value)} }>
                    <option value={"white"}>Fundo branco, QR Code preto</option>
                    <option value={"black"}>Fundo preto, QR Code branco</option>
                    </select>
            </div>

            <button type='submit' className='btn btn-lg btn-primary me-3' onClick={handleDownload}>
                Gerar e baixar
                {isLoading && (<span className='spinner-border spinner-border-sm align-middle ms-2'></span>)}
            </button>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>

    
  </>
)
  }

const QrCodeGeneratorWrapper = () => {
  

  return (
    <>
      <PageTitle description='' breadcrumbs={[]}>
        Gerador de QR Codes
      </PageTitle>
      <QrCodeGeneratorPage />
      </>
  )
}

export {QrCodeGeneratorWrapper}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useRef, useState } from 'react'

import { Field, ErrorMessage, setIn } from 'formik'
import { KTSVG } from '../../../_metronic/helpers'
import axios from 'axios'
import { LinkType } from './types/LinkType'
import { LinkItemForm } from './LinkItemForm'
import { Button, Modal } from 'react-bootstrap'
import { LinkItem } from './types/LinkItem'

type Props = {
    setFieldValue?: any,
    values?: any
}

const Step4: FC<Props> = ({ setFieldValue, values }) => {

    const [types, setTypes] = useState<LinkType[]>([]);
    const [type, setType] = useState<LinkType>();
    const [editingLink, setEditingLink] = useState<LinkItem|null>(null);
    const [insertLink, setInsertLink] = useState(false);
    const [linkItem, setLinkItem] = useState<LinkItem>();


    const getTypes = async () => {
        const { data } = await axios.get('/link-types');
        setTypes(data);
    }

    useEffect(() => {
        getTypes();
    }, []);

    return (
        <div className='w-100'>
            <div className='mb-0 pb-10'>
                <h2 className='fw-bolder d-flex align-items-center text-dark'>
                    Contato e Links
                </h2>
            </div>

            <div className='w-100 pb-10'>
                <a
                    href=""
                    onClick={(evt) => { evt.preventDefault(); setInsertLink(true); setType(undefined) }}
                    className='btn btn-sm btn-light-primary'
                >
                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                    Adicionar
                </a>
            </div>

            <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                        <tr className='fw-bold text-muted'>
                            <th className='min-w-140px'>Tipo</th>
                            <th className='min-w-140px'>Texto</th>
                            <th className='min-w-140px'>Link</th>
                            <th></th>
                        </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                        {values?.links.map((item:any, index:number) => {

                        return (
                        <tr>
                        <td>{item.type?.name}</td>
                        <td>{item.text}</td>
                        <td>{item.value[0]}</td>
                        <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                            <a
                                    target="_blank"
                                    href=""
                                    onClick={(evt) => { evt.preventDefault(); if(index == 0) {return}; var currLinks = [...values.links.slice(0, index-1), values.links[index], values.links[index-1], ...values.links.slice(index+1)]; setFieldValue("links", currLinks) }}
                                    className='btn btn-bg-light btn-active-color-primary btn-sm me-3 pe-5'
                                >
                                    <KTSVG path='/media/icons/duotune/arrows/arr003.svg' className='svg-icon-3 me-0' />
                                </a>
                                <a
                                    target="_blank"
                                    href=""
                                    onClick={(evt) => { evt.preventDefault(); if(index == values.links.length -1) { return }; var currLinks = [...values.links.slice(0, index), values.links[index+1], values.links[index], ...values.links.slice(index+2)]; setFieldValue("links", currLinks); }}
                                    className='btn btn-bg-light btn-active-color-primary btn-sm me-3 pe-5'
                                >
                                    <KTSVG path='/media/icons/duotune/arrows/arr004.svg' className='svg-icon-3 me-0' />
                                </a>
                                <a
                                    target="_blank"
                                    href=""
                                    onClick={(evt) => { evt.preventDefault(); setEditingLink(item); setType(item.type) }}
                                    className='btn btn-bg-light btn-active-color-primary btn-sm me-3 pe-5'
                                >
                                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3 me-0' />
                                </a>
                                <a
                                    target="_blank"
                                    href=""
                                    onClick={(evt) => { evt.preventDefault(); var currLinks = [...values.links]; currLinks.splice(index, 1); setFieldValue("links", currLinks);  }}
                                    className='btn btn-bg-light btn-active-color-primary btn-sm me-3 pe-5'
                                >
                                    <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3 me-0' />
                                </a>
                            </div>

                        </td>
                        </tr>
                        )})}
                    </tbody>
                </table>
            </div>

            {/* <LinkItem types={types} /> */}

            <Modal show={editingLink != null || insertLink} onHide={() => { setType(undefined); setEditingLink(null); setInsertLink(false); }}>
          <Modal.Header> 
            <Modal.Title>Dados do link</Modal.Title>
          </Modal.Header>
          <Modal.Body className='text-center'>
            {insertLink && (
          <div className='mb-5 fv-row text-start'>
            <label className='form-label m-2'>Tipo</label>
                <select className='form-control form-control-lg form-control-solid' onChange={(evt) => { setType(types[evt.target.selectedIndex-1]) }}>
                    <option>Selecione o tipo</option>
                    {types.map((item) => {
                        return (
                            <option>{item.name}</option>
                        )
                    })}
                </select>
                </div>
                )}
            <LinkItemForm type={type} initialValues={editingLink} onChange={(linkItem:LinkItem) => { setLinkItem(linkItem) }} />
          </Modal.Body>
          <Modal.Footer>
          
            <Button variant="secondary" onClick={() => { setEditingLink(null); setInsertLink(false); }}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={async () => { if(insertLink) { setFieldValue("links", [...values.links, linkItem!]); setInsertLink(false); } else { var currLinks = [...values.links]; var index = values.links.findIndex((x:any) => x == editingLink); currLinks[index] = linkItem!; setFieldValue("links", currLinks); setEditingLink(null); }}}>
              OK
            </Button>
            
          </Modal.Footer>
        </Modal>

        </div>

    )
}

export { Step4 }

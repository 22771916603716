import * as Yup from 'yup'
import { FC, useRef, useState, useEffect } from "react"
import { KTSVG } from "../../../_metronic/helpers"
import { useParams } from 'react-router-dom'
import { ErrorMessage, Field } from 'formik'
import { LinkType } from './types/LinkType'
import { LinkItem } from './types/LinkItem'

type Props = {
    type: LinkType | undefined,
    initialValues?: LinkItem | null,
    onChange: any
}

const LinkItemForm: FC<Props> = ({ type, initialValues, onChange }) => {

    const [linkItem, setLinkItem] = useState<LinkItem>({ type: type, text: "", value: [""], orderProfile: 0, showPdf: true });

    useEffect(() => {
        if (type != undefined) {
            setLinkItem({ ...linkItem, type: type, text: type.text, value: [''] });
        } else {
            setLinkItem({ ...linkItem, text: '', value: [''], showPdf: true });
        }
    }, [type]);

    useEffect(() => {
        if(initialValues) {
            setLinkItem(initialValues);
        }
    }, [initialValues])


    useEffect(() => {
        onChange(linkItem);
    }, [linkItem]);



    return (
        <>
            {type && (
                <div className='mb-10 fv-row  mb-3 text-start '>
                    <label className='form-label m-2'>Texto</label>

                    <Field
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        name='text'
                        value={linkItem?.text}
                        onChange={(evt: any) => { if (linkItem) setLinkItem({ ...linkItem!, text: evt.target.value }) }}
                    />
                </div>
            )}

            {type && type.fields.map((field, index) => {
                return (
                    <div className='mb-10 fv-row  mb-3 text-start '>
                        <label className='form-label m-2'>{field}</label>

                        <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            name='value'
                            value={linkItem?.value[index]}
                            onChange={(evt: any) => { var newValues = [...linkItem.value]; newValues[index] = evt.target.value; setLinkItem({ ...linkItem, value: newValues }) }}
                        />

                        
                    </div>
                )
            })}

<div className='mb-10 fv-row text-start'>
                <label className=''>
                    <input type="checkbox" className='form-check-input me-3' name="showPdf" checked={linkItem?.showPdf} onChange={(evt: any) => { if (linkItem) setLinkItem({ ...linkItem!, showPdf: evt.target.checked }) }} />
                    <span className='form-check-label fw-bold text-gray-700'>Exibir no cartão PDF</span>
                </label>
            </div>
        </>
    )
}

export { LinkItemForm }

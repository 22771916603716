import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle} from '../../../_metronic/layout/core'
import { Card } from './types/Card';

const DashboardPage = () => {

  const [cards, setCards] = useState<Card[]>([]);

  const [query, setQuery] = useState('');

  const [deletingCard, setDeletingCard] = useState<Card|null>();

const getData = async () => {
  const { data } = await axios.get("cards");
setCards(data);
}

const deleteCard = async () => {
  await axios.delete(`/cards/${deletingCard!._id}`);
  setDeletingCard(null);
  getData();
}

useEffect(() => {
  getData();
}, [])

  return (
  <>

<div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Clientes</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{cards.length} clientes</span>
        </h3>
        <div
          className='card-toolbar'
          title='Click to add a user'
        >
          <Link
            to="/customer/new"
            className='btn btn-sm btn-light-primary'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            Adicionar cliente
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
      <div className="d-flex align-items-center position-relative my-1">
      <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1 position-absolute ms-6' />
        <input type="text" className="form-control form-control-solid w-400px ps-14" placeholder="Buscar cliente" value={query} onChange={(evt)=> { setQuery(evt.target.value) }} /></div>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>Nome</th>
                <th className='min-w-140px'>Empresa</th>
                <th className='min-w-120px'>Usuário</th>
                <th className='min-w-100px text-end'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {cards.filter(card => { var fullName = `${card.firstName.trim()} ${card.lastName.trim()}`; return query == '' || card.firstName.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) != -1 || card.userCode.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) != -1 || fullName.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) != -1 || (card.company && card.company?.name.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) != -1) }).map((item) => {
                return (
<tr key={item._id}>
                <td>
                  <div className='d-flex align-items-center'>
                    {item.company?.logo && (<div className='symbol symbol-45px me-5'>
                      <img src={item.company?.logo} style={{objectFit: 'contain'}} alt='' />
                    </div>)}
                    {item.picture && (<div className='symbol symbol-45px me-5'>
                      <img src={item.picture} style={{objectFit: 'contain'}} alt='' />
                    </div>)}
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        {item.firstName} {item.lastName}
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        {item.role}
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    {item.company?.name}
                  </a>
                </td>
                <td>
                  /{item.userCode}
                </td>
                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                  <a
                      target="_blank"
                      href={`https://app.nfcon.com.br/${item.userCode}`}
                      className='btn btn-bg-light btn-active-color-primary btn-sm me-3 pe-5'
                    >
                      <i className="bi bi-eye-fill pe-0"></i>
                    </a>
                    <Link
                      to={`/customer/edit/${item._id}`}
                      className='btn btn-bg-light btn-active-color-primary btn-sm me-3 pe-5'
                    >
                      <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3 me-0' />
                    </Link>

                    <Link
                      to={`/customer/print/${item._id}`}
                      className='btn btn-bg-light btn-active-color-primary btn-sm me-3 pe-5'
                    >
                      <i className="bi bi-printer-fill pe-0"></i>
                    </Link>

                    <a
                      className='btn btn-bg-light btn-active-color-primary btn-sm me-1 pe-5'
                      onClick={() => {setDeletingCard(item) }}
                    >
                      <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3 me-0' />
                    </a>
                    
                  </div>
                </td>
              </tr>
                )
              })}
              
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>

    <Modal show={deletingCard != null} onHide={() => { setDeletingCard(null); }}>
          <Modal.Header> 
            <Modal.Title>Excluir cliente</Modal.Title>
          </Modal.Header>
          <Modal.Body className='text-center'>
          Deseja excluir {deletingCard?.firstName ? `${deletingCard?.firstName} ${deletingCard?.lastName}` : deletingCard?.company.name }?<br />
          Esta ação não poderá ser desfeita
            
          </Modal.Body>
          <Modal.Footer>
          
            <Button variant="secondary" onClick={() => { setDeletingCard(null) }}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={async () => { await deleteCard(); }}>
              Excluir
            </Button>
            
          </Modal.Footer>
        </Modal>

    
  </>
)
  }

const DashboardWrapper = () => {
  const {user} = useAuth0();

  return (
    <>
      <PageTitle description='' breadcrumbs={[]}>
        {`Olá, ${user?.name}`}
      </PageTitle>
      <DashboardPage />
      </>
  )
}

export {DashboardWrapper}

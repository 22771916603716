/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title="Clientes"
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/qrcode-generator'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title="Gerador de QR Code"
        fontIcon='bi-app-indicator'
      />
      {/* <AsideMenuItem
        to='/users'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title="Usuários"
        fontIcon='bi-app-indicator'
      /> */}
    </>
  )
}

import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PageTitle } from "../../../_metronic/layout/core";
import { ChromePicker } from "react-color";

const PrintCard = () => {

    const { id } = useParams();
    const [card, setCard] = useState<any>()
    const [selectedCode, setSelectedCode] = useState('');
    const [selectedFont, setSelectedFont] = useState('');
    const [code, setCode] = useState('');
    const [color, setColor] = useState('#000000');

    const getData = async () => {

        const { data } = await axios.get(`cards/${id}`);

        setCard(data);
    }

    useEffect(() => {
        if (card && card.codes.length > 0) {
            setSelectedCode(card.codes[0].shortCode);
        }
    }, [card])

    useEffect(() => {
        getData();
    }, [id]);

    useEffect(() => {
        setCode(selectedCode);
    }, [selectedCode]);

    return (
        <>
            <PageTitle description='' breadcrumbs={[]}>
                {`Imprimir cartão`}
            </PageTitle>

            <div className={`card`}>
                <div className="card-body">
                    <div className='mb-10 fv-row'>
                        <label className='form-label mb-3'>Escolha o código que deseja utilizar</label>
                        <select className="form-control form-control-lg form-control-solid" value={selectedCode} onChange={(e) => { setSelectedCode(e.target.value) }}>
                            {card && card.codes.map((item: any) => {
                                return (
                                    <option value={item.shortCode}>{item.shortCode}</option>
                                )
                            })}
                            <option value=''>outro</option>
                        </select>
                    </div>

                    {selectedCode == '' && (
                        <div className='mb-10 fv-row'>
                            <label className='form-label mb-3'>Digite o código</label>
                            <input
                                type='text'
                                value={code}
                                onChange={(evt) => { setCode(evt.target.value) }}
                                className='form-control form-control-lg form-control-solid'
                                name='companyName'
                            />
                        </div>
                    )}

                    {card && !card.company && (
                        <>
                            <div className='mb-10 fv-row'>
                                <label className='form-label mb-3'>Escolha a fonte para o nome manuscrito</label>
                                <select className="form-control form-control-lg form-control-solid" value={selectedFont} onChange={(e) => { setSelectedFont(e.target.value) }}>
                                    <option value='Cookie'>Cookie</option>
                                    <option value='DancingScript'>Dancing Script</option>
                                    <option value='Satisfy'>Satisfy</option>
                                    <option value='Sacramento'>Sacramento</option>
                                </select>
                            </div>
                            <div className='mb-10 fv-row'>
                                <label className='form-label mb-3'>Escolha a cor para o nome manuscrito</label>
                                <ChromePicker color={color} onChangeComplete={(newColor:any) => { setColor(newColor.hex) }} />
                            </div>
                        </>
                    )}


                    <div className='d-flex flex-stack'>
                        <div>
                            {card && code != '' && (
                                <button type='submit' className='btn btn-lg btn-primary me-3' onClick={() => { window.open(`${process.env.REACT_APP_API_URL}/card/${id}/card-pdf?cardId=${code}`, '_blank'); }}>
                                    Gerar cartão (verso)
                                </button>
                            )}
                        </div>
                        {card && card.company && card.company.logo && (
                            <div>
                                <button type='submit' className='btn btn-lg btn-primary me-3' onClick={() => { window.open(`${process.env.REACT_APP_API_URL}/card/${id}/card-pdf-logo`, '_blank'); }}>
                                    Gerar cartão (logo)
                                </button>
                            </div>
                        )}


                        {card && !card.company && (
                            <div>
                                <button type='submit' className='btn btn-lg btn-primary me-3' onClick={() => { window.open(`${process.env.REACT_APP_API_URL}/card/${id}/card-pdf-name?font=${selectedFont}&color=${encodeURIComponent(color)}`, '_blank'); }}>
                                    Gerar cartão (nome manuscrito)
                                </button>
                            </div>
                        )}

                    </div>

                </div>
            </div>

        </>
    )
}

export { PrintCard }
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useRef, useState } from 'react'

import { ChromePicker } from 'react-color'

import { Field, ErrorMessage } from 'formik'
import { KTSVG } from '../../../_metronic/helpers'

type Props = {
    setFieldValue?: any,
    values?: any
}

const Step3: FC<Props> = ({ setFieldValue, values }) => {


    const inputFile = useRef<HTMLInputElement | null>(null);
    const pictureFile = useRef<HTMLInputElement | null>(null);



    const [logoImage, setLogoImage] = useState<string | null>('');

    const handleUpload = () => {
        inputFile.current?.click();
    }

    const handleChangeFile = (evt: any) => {
        console.log('file changed', evt.target.files)

        setFieldValue("pictureFile", evt.target.files[0]);

        var reader = new FileReader();
        reader.readAsDataURL(evt.target.files[0]);
        reader.onload = function () {
            setLogoImage(reader.result!.toString());

        };

    }

    useEffect(() => {
        if((logoImage == '' || logoImage == null) && values?.picture != logoImage) {
            setLogoImage(values?.picture);
        }

        if(values && values.accountType == 'corporate') {
            setFieldValue("pictureFile", null); 
            if(inputFile.current)
                inputFile.current!.value = '';
        }

    }, [values]);

    return (
        <div className='w-100'>
            <div className='pb-10 pb-lg-15'>
                <h2 className='fw-bolder d-flex align-items-center text-dark'>
                    Dados do perfil
                </h2>
            </div>

            <div className='mb-10 fv-row'>
                <label className='form-label mb-3'>Primeiro nome</label>

                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='firstName'
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='firstName' />
                </div>
            </div>
            <div className='mb-10 fv-row'>
                <label className='form-label mb-3'>Sobrenome</label>

                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='lastName'
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='lastName' />
                </div>
            </div>
            <div className='mb-10 fv-row'>
                <label className='form-label mb-3'>Cargo</label>

                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='role'
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='role' />
                </div>
            </div>
            <div className='mb-10 fv-row'>
                <label className='form-label mb-3'>Bio</label>

                <Field
                    component='textarea'
                    className='form-control form-control-lg form-control-solid'
                    name='bio'
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='bio' />
                </div>
            </div>
            <div className='mb-10 fv-row'>
                <label className='form-label mb-3'>Nome de usuário</label>

                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='username'
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='username' />
                </div>
            </div>
            <div className='mb-10 fv-row'>
                <label className='form-label mb-3'>Cor de destaque</label>

                <ChromePicker color={values?.cardColor} onChangeComplete={(color) => { setFieldValue("cardColor", color.hex) }} />
            </div>
            {values && values.accountType && values.accountType == 'personal' && (
            <div className='mb-10 fv-row'>
                <label className='form-label mb-3'>
                    Foto de perfil
                </label>


                <div className='py-4' style={{ border: '1px dashed #ccc', borderRadius: '5px', textAlign: 'center', position: "relative" }} onClick={handleUpload}>
                    {logoImage ? (<><i className='bi bi-pencil' style={{ fontSize: '16px', position: 'absolute', right: '10px', top: '10px' }}></i><img className='mw-250px mh-80px' src={logoImage} /></>) : (
                        <><i className='bi bi-cloud-arrow-up' style={{ fontSize: '24px' }}></i>  <h5 className='text-gray-800'>Fazer upload do logo</h5> <div>Clique para selecionar um arquivo</div></>
                    )}

                    <input type="file" ref={inputFile} onChange={handleChangeFile} style={{ display: 'none' }} accept="image/png, image/jpg, image/jpeg" />
                </div>
                {logoImage && (
                    <div className="form-text text-right"><a href='javascript:;' onClick={() => { setFieldValue("pictureFile", null); setFieldValue("picture", null); setLogoImage(null); inputFile.current!.value = ''; }}>remover foto</a></div>
                )}
            </div>)}

        </div>
    )
}

export { Step3 }
